<template>
  <v-autocomplete
    label="Tipos"
    placeholder="Seleccione un tipo de gasto"
    :loading="loading"
    :items="items"
    item-text="type"
    item-value="id"
    return-object
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :search-input.sync="search"
    ref="auto"
    :rules="onlyRequired"
  ></v-autocomplete>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    reset: { type: Boolean, required: false },
    value: [Object],
    reload: [Boolean],
  },
  data: () => ({
    loading: false,
    items: [],
    onlyRequired: [rules.required,],
    search: "",
  }),
  methods: {
    getTypeProjects(param = "") {
      this.loading = true;
      let uri = "/api/project/type-detail/?search=";
      if (this.search) {
        uri += param;
      }
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.items = res.data.results;
        } else {
          console.log(res);
        }
      });
      this.loading = false;
    },
  },
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
    reload: function () {
      this.getTypeProjects();
    },
    search: function () {
      this.getTypeProjects(this.search);
    },
  },
  mounted() {
    this.getTypeProjects();
  },
};
</script>
